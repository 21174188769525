import React, { Suspense, useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  // Navigate,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.css";
import Sidebar from "./components/Sidebar";

const Login = React.lazy(() => import("./pages/Login"));
const Home = React.lazy(() => import("./pages/Home"));
const Transactions = React.lazy(() => import("./pages/Transactions"));
const Users = React.lazy(() => import("./pages/Users"));
const Billings = React.lazy(() => import("./pages/Billings"));
const Agents = React.lazy(() => import("./pages/Agents"));
const AgentsReport = React.lazy(() => import("./pages/AgentsReport"));

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mainAreaClass, setMainAreaClass] = useState("w-full");

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    }
  }, []);

  useEffect(() => {
    if (!token && location.pathname !== "/") {
      navigate("/");
    }
  }, [token, location.pathname]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (!sidebarOpen) {
      setMainAreaClass("translate-x-0 md:ml-[80px] ml-[50px] mainCloseWidth");
    } else {
      setMainAreaClass("translate-x-64 mainOpenWidth");
    }
  }, [sidebarOpen]);

  return (
    <React.Fragment>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-screen">
            Loading...
          </div>
        }
      >
        <div className="bg-gray-100 flex overflow-x-hidden">
          <div className={location.pathname === "/" ? "hidden" : ""}>
            <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
          </div>

          <div
            className={`h-screen transition-transform duration-300 px-[10px] py-[10px] ${
              location.pathname === "/" ? "w-full" : mainAreaClass
            }`}
            id="mainArea"
          >
            <Routes>
              <Route path="/" element={<Login />} />
              {token ? (
                <>
                  <Route path="/home" element={<Home />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/billings" element={<Billings />} />
                  <Route path="/agents" element={<Agents />} />
                  <Route path="/agents_report" element={<AgentsReport />} />
                </>
              ) : (
                <Route path="/" element={<Login />} />
              )}
            </Routes>
          </div>
        </div>
      </Suspense>
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
