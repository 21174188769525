import React from "react";
import { Link, useLocation } from "react-router-dom";

const Dropdown = ({ options, isOpen }) => {
  const location = useLocation();
  return (
    <ul className="w-full">
      {options.map((subItem, subIndex) => (
        <li
          key={subIndex}
          className={`p-2 my-1 w-full rounded text-gray-400 hover:text-white hover:bg-[#ffffff14] ${
            location.pathname === subItem.link
              ? "bg-[#ffffff14] text-white"
              : ""
          }`}
          title={subItem.label}
        >
          <Link to={subItem.link}>
            <div className={`flex ${isOpen ? "" : "justify-center"}`}>
              <span>{subItem.icon}</span>
              <span className={`${isOpen ? "ml-2" : "hidden"}`}>
                {subItem.label}
              </span>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;
