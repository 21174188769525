import React, { useState } from "react";
import Dropdown from "./Dropdown";
import { Link, useLocation } from "react-router-dom";
import { logo, mobileMenu, closeMenu } from "../constants/images";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();

  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownClick = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    window.location.href = "/";
  };

  const menuItems = [
    {
      id: 1,
      label: "Transactions",
      icon: <i className="bx bxs-dashboard"></i>,
      link: "/transactions",
    },
    {
      id: 2,
      label: "Users",
      icon: <i className="bx bx-user"></i>,
      link: "/users",
    },
    {
      id: 3,
      label: "Billings",
      icon: <i className="bx bx-dollar-circle"></i>,
      link: "/billings",
    },
    {
      id: 4,
      label: "Agents",
      icon: <i className="bx bx-briefcase-alt-2"></i>,
      link: "/agents",
    },

    {
      id: 6,
      label: "Agents Report",
      icon: <i className="bx bxs-report"></i>,
      link: "/agents_report",
    },
  ];

  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.[user.length - 1];

  // Filter the menu items based on the user's role
  const filteredMenuItems = menuItems.filter((item) => {
    if (userRole === "admin") {
      return true;
    } else {
      return item.label !== "Users";
    }
  });

  return (
    <div
      className={`bg-white shadow-lg h-screen w-[250px] fixed top-0 left-0 transition-all duration-300 ${
        isOpen ? "translate-x-0" : "md:w-[80px] w-[50px] -translate-x-[0px]"
      }`}
    >
      <div
        className={`bg-gray-100 p-4 flex ${
          isOpen ? "flex-row" : "flex-col"
        } items-center justify-between`}
      >
        <div>
          {isOpen ? (
            <div className="flex space-x-2 items-center">
              <img src={logo} alt="logo" className="w-[40px]" />
              <h4 className="text-[18px] font-bold">Wali Textile</h4>
            </div>
          ) : (
            <img src={logo} alt="logo" className="w-[60px]" />
          )}
        </div>

        <div>
          <button
            onClick={toggleSidebar}
            className="flex items-center justify-center w-[30px] h-[30px]"
          >
            <img
              src={isOpen ? closeMenu : mobileMenu}
              alt={isOpen ? closeMenu : mobileMenu}
              className="w-[18px]"
            />
          </button>
        </div>
      </div>

      <ul className="mt-4 p-4">
        {filteredMenuItems.map((item, index) => (
          <React.Fragment key={item.id}>
            {item.link ? (
              <Link to={item.link}>
                <li
                  key={index}
                  className={`relative my-2 text-gray-400 hover:text-white px-2 hover:rounded-lg hover:bg-slate-800 ${
                    location.pathname === item.link
                      ? "bg-slate-800 rounded-lg text-white"
                      : ""
                  }`}
                  title={item.label}
                >
                  <button className="py-2 w-full text-start">
                    <div
                      className={`flex items-center ${
                        isOpen ? "" : "justify-center"
                      }`}
                    >
                      <div className={`flex ${isOpen ? "mr-4" : ""}`}>
                        {/* <img src={item.icon} alt={item.icon} className='w-[15px] filter invert brightness-0 grayscale-100' /> */}
                        {item.icon}
                      </div>
                      <p className={`${isOpen ? "" : "hidden"}`}>
                        {item.label}
                      </p>

                      {item.dropdown && item.dropdown.length > 0 && (
                        <span
                          className={`ml-auto transition-transform transform ${
                            isOpen ? "" : "hidden"
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-4 w-4 ${
                              openDropdown === index ? "rotate-0" : "rotate-180"
                            }`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  </button>

                  {openDropdown === index && (
                    <Dropdown options={item.dropdown} isOpen={isOpen} />
                  )}
                </li>
              </Link>
            ) : (
              <li
                key={index}
                className="relative my-0 text-gray-400 hover:text-white"
                title={item.label}
              >
                <button
                  className="py-2 w-full text-start"
                  onClick={() => handleDropdownClick(index)}
                >
                  <div
                    className={`flex items-center ${
                      isOpen ? "" : "justify-center"
                    }`}
                  >
                    <div className={`flex ${isOpen ? "mr-2" : ""}`}>
                      <img
                        src={item.icon}
                        alt={item.icon}
                        className="w-[15px] filter invert brightness-0 grayscale-100"
                      />
                    </div>
                    <p className={`${isOpen ? "" : "hidden"}`}>{item.label}</p>

                    {item.dropdown && item.dropdown.length > 0 && (
                      <span
                        className={`ml-auto transition-transform transform ${
                          isOpen ? "" : "hidden"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-4 w-4 ${
                            openDropdown === index ? "rotate-0" : "rotate-180"
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </button>

                {openDropdown === index && (
                  <Dropdown options={item.dropdown} isOpen={isOpen} />
                )}
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>

      <div className="absolute bottom-4 px-4 w-full">
        <button
          type="button"
          onClick={handleLogout}
          className="border border-[#5b2a89] hover:bg-slate-800 hover:text-white rounded-full px-6 py-2 w-full"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
